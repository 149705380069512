<template>
  <div class="message-list">
    <error :errors="userError" />
    <b-overlay :show="formBusy">
      <portlet class="kt-portlet--tabs" title="ئاكتىپ ئەزالار ئۇچۇرى" >
        <template v-slot:toolbar>
          <b-row class="align-items-center d-none d-sm-flex ml-2">
            <b-col md="2" sm="2" >
              <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm ">
                يېڭىلاش
              </button>
            </b-col>
          </b-row>
        </template>
        <template v-slot:body>
          <!--          ئۈستىدىكى سىتاستىكا باشلاندى-->
          <div class="row">
            <div class="col">
              <!--Begin::Section-->
              <div class="row row-no-padding row-col-separator-xl">
                <div class="col-md-12 col-lg-12 col-xl-4">
                  <!--begin:: Widgets/Stats2-1 -->
                  <div class="kt-widget1">
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title"> ئاكتىپ ئەزالار</h3>
                        <span class="kt-widget1__desc">بارلىق ئاكتىپ ئەزالار</span>
                      </div>
                      <span class="kt-widget1__number kt-font-brand"> نەپەر  {{ allUser }} </span>
                    </div>
                  </div>
                  <!--end:: Widgets/Stats2-1 -->
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">
                  <!--begin:: Widgets/Stats2-2 -->
                  <div class="kt-widget1">
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">قىزلار</h3>
                        <span class="kt-widget1__desc">بارلىق ئاكتىپ قىزلار</span>
                      </div>
                      <span class="kt-widget1__number kt-font-success"> نەپەر {{ woManUser }}</span>
                    </div>
                  </div>
                  <!--end:: Widgets/Stats2-2 -->
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">
                  <!--begin:: Widgets/Stats2-3 -->
                  <div class="kt-widget1">
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">ئوغۇللار</h3>
                        <span class="kt-widget1__desc">بارلىق ئاكتىپ ئوغۇللار</span>
                      </div>
                      <span class="kt-widget1__number kt-font-success"> نەپەر {{ manUser }} </span>
                    </div>
                  </div>
                  <!--end:: Widgets/Stats2-3 -->
                </div>
              </div>
              <!--End::Section-->
            </div>
          </div>
          <!--          ئۈستىدىكى سىتاستىكا ئاياقلاشتى-->
        </template>
      </portlet>
    </b-overlay>
    <b-overlay :show="formBusy">
      <portlet class="kt-portlet--tabs" title="ئاكتىپ ئەزالارغا ئۇچۇر يوللاش" >
        <template v-slot:toolbar>
          <ul class="nav nav-tabs nav-tabs-space-xl nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#textMessage" role="tab" aria-selected="true">
                <i class="fa fa-envelope"></i> خەتلىك ئۇچۇر
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#newsMessage" role="tab" aria-selected="false">
                <i class="fa fa-list-alt"></i> تېمىلىق ئۇچۇر
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#imageMessage" role="tab" aria-selected="false">
                <i class="fa fa-image"></i> رەسىملىك ئۇچۇر
              </a>
            </li>
          </ul>
        </template>
        <template v-slot:body>
          <b-row class="m-t-3 tab-content">
            <b-col cols="12">
              <div class="alert alert-solid-brand" role="alert">
                <div class="alert-text">
                  <h4 class="alert-heading">ياردەم ئۇچۇرى</h4>
                  <p>مەزمۇن يوللاش جەريانىدا، تۆۋەندىكى ھالقىلىق سۆزلەرنى ئىشلىتىش ئارقىلىق، مەزمۇن ئارىسىغا ئەزا ئۇچۇرىنى قىستۇرۇش مەقسىدىگە يەتسىڭىز بولىدۇ . ھالقىلىق سۆزنى بېسىش ئارقىلىق كۆچۈرۈپ ئىشلىتىڭ .</p>
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <b-row class="mb-1">
                        <b-col class="text-right"><code style="cursor: pointer" v-clipboard="'[nickname]'" v-clipboard:success="clipboardSuccessHandler">[nickname]</code></b-col>
                        <b-col cols="11">ئەزانىڭ تور نامىغا ئالماشتۇرۇلىدۇ</b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="text-right"><code style="cursor: pointer" v-clipboard="'[openid]'" v-clipboard:success="clipboardSuccessHandler">[openid]</code></b-col>
                        <b-col cols="11">ئەزانىڭ openid نۇمۇرىغا ئالماشتۇرۇلىدۇ</b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="text-right"><code style="cursor: pointer" v-clipboard="'[gender]'" v-clipboard:success="clipboardSuccessHandler">[gender]</code></b-col>
                        <b-col cols="11">ئەزانىڭ جىنسى ئاتالغۇسىغا ئالماشتۇرۇلىدۇ</b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col class="text-right"><code style="cursor: pointer" v-clipboard="'[time]'" v-clipboard:success="clipboardSuccessHandler">[time]</code></b-col>
                        <b-col cols="11">نۆۋەتتىكى چىسلا ۋە ۋاقىتقا ئالماشتۇرۇلىدۇ</b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
            </b-col>
            <b-col cols="12" class="tab-pane active" role="tabpanel"  id="textMessage">
              <text-message :all-user="allUser" :man-user="manUser" :wo-man-user="woManUser" @send="onSendMessage" />
            </b-col>
            <b-col cols="12" class="tab-pane" role="tabpanel"  id="newsMessage">
              <news-message :all-user="allUser" :man-user="manUser" :wo-man-user="woManUser" @send="onSendMessage" />
            </b-col>
            <b-col cols="12" class="tab-pane" role="tabpanel"  id="imageMessage">
              <image-message :all-user="allUser" :man-user="manUser" :wo-man-user="woManUser" @send="onSendMessage" id="imageNews" />
            </b-col>
          </b-row>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../../../partials/content/Portlet";
  import Error from "../../../../partials/widgets/Error";
  import TextMessage from "../../../../components/customer/TextMessage";
  import NewsMessage from "../../../../components/customer/NewsMessage";
  import ImageMessage from "../../../../components/customer/ImageMessage";
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import sendMessageMixin from "../../../../../mixins/sendMessage.mixin";
  import activeUserMixin from "../../../../../mixins/weChat/activeUser.mixin";
  import { mapGetters } from "vuex";
  import Swal from "sweetalert2";
  export default {
    name: "Message",
    components: { Error, Portlet, TextMessage, NewsMessage, ImageMessage },
    mixins: [ formBusyMixin, sendMessageMixin, activeUserMixin ],
    computed: {
      ...mapGetters(['userError'])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەزا باشقۇرۇش", route: "list" },
        { title: "سالون ئاكتىپ ئەزالىرى" },
        { title: " ئەزالارغا ئۇچۇر يوللاش " }
      ]);
    },
    created() {
      this.getCount();
      this.getCount(1);
      this.getCount(2);
    },
    data(){
      return {
        formBusy: false,
        allUser: null,
        manUser: null,
        woManUser: null
      };
    },
    methods: {
      refreshList(){
        this.getCount();
        this.getCount(1);
        this.getCount(2);
      },
      clipboardSuccessHandler(e){
        Swal.fire({
          text: 'كۆچۈرۈلدى',
          icon: 'success',
          confirmButtonText: 'بىلدىم'
        })
      },
      onSendMessage(e){
        this.showBusy();
        let { formData } = e;
        this.sendMessage(formData)
          .then(response=>{
            this.$router.replace({ name: 'user.we_chat.customer.history' });
            this.hideBusy();
          })
          .catch(response=>{
            console.log(response);
            Swal.fire({
              text: this.userError,
              icon: 'warning'
            })
            this.hideBusy();
          })
      },
      getCount(filter = 0){
        this.getSocialiteAccountCount(filter, true)
          .then(response=>{
            let { count } = response.data;
            switch (filter) {
              case 0:
                this.allUser =count;
                break;
              case 1:
                this.manUser = count;
                break;
              case 2:
                this.woManUser = count;
                break;
            }
          })
      }
    }
  }
</script>

<style scoped>

</style>

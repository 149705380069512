import ApiService from "../common/api.service";
import handleReject from "../common/handleReject";
import { SET_USER_ERROR } from "../store/modules/user";

export default {
  methods: {
    sendMessage(message = {}){
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(`/manager/we-chat/customer/message`, message)
        .then(response=>{
          resolve(response);
          this.$store.commit(SET_USER_ERROR, null);
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_USER_ERROR)
          reject(response);
        })
      });
    }
  }
}

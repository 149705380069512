<template>
  <div class="news-message-container">
    <b-form ref="textMessage" @submit.stop.prevent="onSubmit" @reset="onReset">
      <b-form-row class="row">
        <b-col cols="12">
          <b-form-group id="title-group" label="تېما ماۋزوسى" label-for="title">
            <b-form-input id="title" v-model="formData.title" placeholder="يوللايدىغان تېمىنىڭ ماۋزوسىنى كىرگۈزۈڭ، ئۈستىدىكى ھالقىلىق سۆزنى قىستۇرسىڭىز بولىدۇ" :state="validateState('title')" aria-describedby="title-live-feedback" />
            <b-form-invalid-feedback id="title-live-feedback">
              يوللايدىغان تېمىنىڭ ماۋزوسىنى كىرگۈزۈڭ
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group id="description-group" label="تېما چۈشەنچىسى" label-for="description">
            <b-form-input id="description" v-model="formData.description" placeholder="يوللايدىغان تېمىنىڭ چۈشەنچىسىنى كىرگۈزۈڭ، ئۈستىدىكى ھالقىلىق سۆزنى قىستۇرسىڭىز بولىدۇ" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group id="url-group" label="تېما ئۇلانما ئادېرسى" label-for="url">
            <b-form-input id="url" dir="ltr" v-model="formData.url" :state="validateState('url')" aria-describedby="url-live-feedback" placeholder="يوللايدىغان تېمىنىڭ ئۇلانما ئادېرسىنى كىرگۈزۈڭ، ئۈستىدىكى ھالقىلىق سۆزنى قىستۇرسىڭىز بولىدۇ" />
            <b-form-invalid-feedback id="title-live-feedback">
              يوللايدىغان تېمىنىڭ ئۇلانما ئادېرسىنى كىرگۈزۈڭ
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group id="image-group" label="تېما رەسىمى" :state="validateState('image')" aria-describedby="image-live-feedback" label-for="image">
            <image-uploader
              ref="uploader"
              :upload-form="{'strategy': 'news_image'}"
              :img-url="formData.image"
              @success="onUploadSuccess"
            />
            <b-form-invalid-feedback id="image-live-feedback">
              يوللايدىغان تېمىنىڭ رەسىمىنى تاللاڭ
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group id="filter-group" label="تاسقاش ھالىتى" label-for="filter">
            <b-form-radio-group v-b-tooltip :title="filterToolTip" id="filter" v-model="formData.filter" @change="changeFilter" :options="filterOptions" buttons name="radios-btn-default" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row >
        <b-col sm="6" md="3">
          <b-form-group id="input-group-6" label="يوللىنىش ۋاقتىنى تاللاڭ" label-for="start_time">
            <datetime type="datetime" value-zone="PRC" placeholder="بۇ يەرنى بېسىپ تاللاڭ" inputId="start_time" v-model="formData.start_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-button id="submit" type="submit" variant="primary">
        يوللاش
      </b-button>
      <b-button class="ml-2" type="reset" variant="danger">
        بىكار قىلىش
      </b-button>
    </b-form>
  </div>
</template>

<script>
  import Swal from "sweetalert2";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  import ImageUploader from "../../partials/layout/_ImageUploader";
  export default {
    name: "NewsMessage",
    props: {
      allUser: {
        type: Number,
        default: 0
      },
      manUser: {
        type: Number,
        default: 0
      },
      woManUser: {
        type: Number,
        default: 0
      }
    },
    components: {ImageUploader, Datetime },
    mixins: [validationMixin],
    validations: {
      formData: {
        title: {required},
        url: {required},
        image: {required},
      }
    },
    computed: {
      uploader(){
        return this.$refs.hasOwnProperty('uploader') ? this.$refs.uploader : {};
      },
      filePond(){
        if ( this.uploader.$refs ){
          return this.uploader.$refs.pond;
        }
        return {};
      },
    },
    data(){
      return {
        formData: {
          message: 'news',
          title: '',
          image: '',
          url: '',
          description: '',
          filter: 0,
          start_time: ''
        },
        filterOptions: [
          { text: 'ھەممىسىگە', value: 0 },
          { text: 'ئوغۇللارغا', value: 1 },
          { text: 'قىزلارغا', value: 2 },
        ],
        filterToolTip: `نۆۋەتتىكى ئۇچۇرنىڭ يوللىنىش ئوبېكتى، مەسلەن قىزلارغىلا، ياكى ئوغۇللارغىلا يوللاش دىگەن گەپ.`
      }
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(e){
        switch (this.formData.filter) {
          case 0:
            if ( this.allUser < 1 )
              return this.fire('ھازىرچە ئاكتىپ ئەزا يوق ئىكەن');
            break;
          case 1:
            if ( this.manUser < 1 )
              return this.fire('ھازىرچە ئوغۇل ئاكتىپ ئەزا يوق ئىكەن');
            break;
          case 2:
            if ( this.woManUser < 1 )
              return this.fire('ھازىرچە قىز ئاكتىپ ئەزا يوق ئىكەن');
            break;
        }
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          Swal.fire({
            title: 'ئەسكەرتىش!',
            text: 'تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ',
            icon: 'warning',
            confirmButtonText: 'بىلدىم',
          });
          return void 0;
        }
        this.$bvModal.msgBoxConfirm('ئۇچۇر يوللاشنى جەزىملەشتۈرەمسىز ؟ جەزىملەشتۈرگەندىن كىيىن ئۇچۇر يوللاشنى توختاتقىلى بولمايدۇ .',{
          title: 'جەزىملەشتۈرەمسىز ؟',
          size: 'sm',
          okTitle: 'شۇنداق',
          cancelTitle: 'ياق',
          centered: true,
          hideHeaderClose: true,
          footerClass: 'border-top-0',
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        })
          .then(value=>{
            if ( value ){
              let {formData} = this;
              this.$emit('send', {formData})
            }
          })
      },
      onReset(){
        if ( this.formData.image )
          this.filePond.removeFiles();
        this.formData = {
          message: 'news',
          title: '',
          image: '',
          url: '',
          description: '',
          filter: 0,
          start_time: ''
        };
      },
      changeFilter(e){},
      onUploadSuccess(response){
        this.formData.image = response.path;
      },
      fire(message, icon = 'warning'){
        return Swal.fire({
          title: 'ئەسكەرتىش!',
          text: message,
          icon: icon,
          confirmButtonText: 'بىلدىم',
        });
      }
    }
  }
</script>

<style scoped></style>

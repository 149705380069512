import ApiService from "../../common/api.service";

export default {
  methods: {
    getSocialiteAccountCount(filter = 0, busy = false){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/manager/we-chat/socialites/active-user-statics`, `?filter=${filter}`)
        .then(response=>{
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          reject(response);
          busy && this.hideBusy();
        })
      });
    }
  }
}

<template>
  <div class="text-message-container">
    <b-form ref="textMessage" @submit.stop.prevent="onSubmit" @reset="onReset">
      <b-form-row class="row">
        <b-col cols="12">
          <b-form-group id="content-group" label="مەزمۇن" label-for="content">
            <b-form-textarea v-model="formData.content" :state="validateState('content')" aria-describedby="content-live-feedback" />
            <b-form-invalid-feedback id="content-live-feedback">
              يوللايدىغان مەزمۇننى كىرگۈزۈڭ
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="4">
          <b-form-group id="filter-group" label="تاسقاش ھالىتى" label-for="filter">
            <b-form-radio-group v-b-tooltip :title="filterToolTip" id="filter" v-model="formData.filter" @change="changeFilter" :options="filterOptions" buttons name="radios-btn-default" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row >
        <b-col sm="6" md="3">
          <b-form-group id="input-group-6" label="يوللىنىش ۋاقتىنى تاللاڭ" label-for="start_time">
            <datetime type="datetime" value-zone="PRC" placeholder="بۇ يەرنى بېسىپ تاللاڭ" inputId="start_time" v-model="formData.start_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-button id="submit" type="submit" variant="primary">
        يوللاش
      </b-button>
      <b-button class="ml-2" type="reset" variant="danger">
        بىكار قىلىش
      </b-button>
    </b-form>
  </div>
</template>

<script>
  import Swal from "sweetalert2";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  import Portlet from "../../partials/content/Portlet";
  export default {
    name: "TextMessage",
    props: {
      allUser: {
        type: Number,
        default: 0
      },
      manUser: {
        type: Number,
        default: 0
      },
      woManUser: {
        type: Number,
        default: 0
      }
    },
    components: { Portlet, Datetime },
    mixins: [validationMixin],
    validations: {
      formData: {
        content: {required}
      }
    },
    data(){
      return {
        formData: {
          message: 'text',
          content: '',
          filter: 0,
          start_time: ''
        },
        filterOptions: [
          { text: 'ھەممىسىگە', value: 0 },
          { text: 'ئوغۇللارغا', value: 1 },
          { text: 'قىزلارغا', value: 2 },
        ],
        filterToolTip: `نۆۋەتتىكى ئۇچۇرنىڭ يوللىنىش ئوبېكتى، مەسلەن قىزلارغىلا، ياكى ئوغۇللارغىلا يوللاش دىگەن گەپ.`
      }
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(e){
        switch (this.formData.filter) {
          case 0:
            if ( this.allUser < 1 )
              return this.fire('ھازىرچە ئاكتىپ ئەزا يوق ئىكەن');
            break;
          case 1:
            if ( this.manUser < 1 )
              return this.fire('ھازىرچە ئوغۇل ئاكتىپ ئەزا يوق ئىكەن');
            break;
          case 2:
            if ( this.woManUser < 1 )
              return this.fire('ھازىرچە قىز ئاكتىپ ئەزا يوق ئىكەن');
            break;
        }
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          Swal.fire({
            title: 'ئەسكەرتىش!',
            text: 'تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ',
            icon: 'warning',
            confirmButtonText: 'بىلدىم',
          });
          return void 0;
        }
        this.$bvModal.msgBoxConfirm('ئۇچۇر يوللاشنى جەزىملەشتۈرەمسىز ؟ جەزىملەشتۈرگەندىن كىيىن ئۇچۇر يوللاشنى توختاتقىلى بولمايدۇ .',{
          title: 'جەزىملەشتۈرەمسىز ؟',
          size: 'sm',
          okTitle: 'شۇنداق',
          cancelTitle: 'ياق',
          centered: true,
          hideHeaderClose: true,
          footerClass: 'border-top-0',
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
        })
        .then(value=>{
          if ( value ){
            let {formData} = this;
            this.$emit('send', {formData})
          }
        })
      },
      onReset(){
        this.formData = {
          content: '',
          filter: 0,
          start_time: ''
        };
      },
      changeFilter(e){},
      fire(message, icon = 'warning'){
        return Swal.fire({
          title: 'ئەسكەرتىش!',
          text: message,
          icon: icon,
          confirmButtonText: 'بىلدىم',
        });
      }
    }
  }
</script>

<style scoped></style>
